import React from 'react';
import Header from '../components/Header';

const Hikes = () => {
  return (
    <div>
      <Header />
      hikes
    </div>
  );
};

export default Hikes;
